import request from '@/utils/request'
import { AxiosPromise } from 'axios'
import { LoginResult } from '@/api/types'

/**
 * 今日
 * @param data {LoginData}
 * @returns
 */
export function getList(params: any) {
  return request({
    url: `/meet/Index/getList?page=${params.page}&searchData=${params.searchData}`,
    method: 'get'
  })
}

